@font-face {
  font-display: swap;
  font-family: Quicksand;
  src: url("Quicksand-VariableFont_wght.67fb546e.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: OpenSans;
  src: url("OpenSans-VariableFont_wdth,wght.773d0334.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Raleway;
  src: url("Raleway-VariableFont_wght.78e99da2.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Ubuntu-Bold;
  src: url("Ubuntu-Bold.228fa246.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: FiraSans-ExtraBold;
  src: url("FiraSans-ExtraBold.fe5476d4.ttf") format("truetype");
}

*, * * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
  background-color: #111;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
}

body.no-scroll {
  overflow: hidden;
}

body #root {
  width: 100%;
  height: 100%;
  font-family: Raleway, sans-serif;
}

body p {
  color: #2c3546;
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 3.6rem;
}

body a {
  font-size: 2rem;
  text-decoration: none;
}

body h1, body h2, body h3, body h4, body h5, body h6 {
  margin-bottom: 1rem;
  font-weight: 400;
}

body h1 {
  font-size: 6rem;
}

body h2 {
  font-size: 4.8rem;
}

body h3 {
  font-size: 4rem;
}

body h4 {
  font-size: 3.2rem;
}

body h5 {
  font-size: 2.8rem;
}

body h6 {
  font-size: 2.4rem;
}

body pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

body pre code {
  border-radius: 1rem;
  font-size: 1.8rem;
}

body hr {
  border-top: .1rem solid #0000001a;
  margin: 1rem;
}
/*# sourceMappingURL=index.53286736.css.map */
