@font-face {
	font-display: swap;
	font-family: 'Quicksand';
	src: url('../static/fonts/Quicksand/Quicksand-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'OpenSans';
	src: url('../static/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Raleway';
	src: url('../static/fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Ubuntu-Bold';
	src: url('../static/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'FiraSans-ExtraBold';
	src: url('../static/fonts/Fira_Sans/FiraSans-ExtraBold.ttf') format('truetype');
}
