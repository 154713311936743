// Global stylesheet and simple CSS reset

@import 'fonts';
@import 'variables';

*, * * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	position: relative;
}

html {
	font-size: 62.5%;
	height: 100%;
	width: 100%;
}

body {
	background-color: $darker-gray;
	font-family: sans-serif;
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-text-stroke: 1px transparent;

	&.no-scroll {
		overflow: hidden;
	}

	#root {
		font-family: Raleway, sans-serif;
		height: 100%;
		width: 100%;
	}

	p {
		color: $med-gray;
		font-weight: 300;
		font-size: 2rem;
		line-height: 3.6rem;
		margin-bottom: 2.4rem;
	}

	a {
		font-size: 2rem;
		text-decoration: none;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 400;
		margin-bottom: 1rem;
	}

	h1 { font-size: 6.0rem; }
	h2 { font-size: 4.8rem; }
	h3 { font-size: 4.0rem; }
	h4 { font-size: 3.2rem; }
	h5 { font-size: 2.8rem; }
	h6 { font-size: 2.4rem; }

	pre {
		white-space: pre-wrap;
		white-space: -moz-pre-wrap;
		white-space: -o-pre-wrap;
		word-wrap: break-word;

		code {
			border-radius: 1rem;
			font-size: 1.8rem;
		}
	}

	hr {
		border-top: 0.1rem solid rgba(0,0,0,0.1);
		margin: 1rem;
	}
}
